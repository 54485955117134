import 'semantic-ui-css/components/accordion';
import 'semantic-ui-css/components/api';
import 'semantic-ui-css/components/checkbox';
import 'semantic-ui-css/components/colorize';
import 'semantic-ui-css/components/dimmer';
import 'semantic-ui-css/components/dropdown';
import 'semantic-ui-css/components/embed';
import 'semantic-ui-css/components/form';
import 'semantic-ui-css/components/modal';
import 'semantic-ui-css/components/nag';
import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/progress';
import 'semantic-ui-css/components/rating';
import 'semantic-ui-css/components/search';
import 'semantic-ui-css/components/shape';
import 'semantic-ui-css/components/sidebar';
import 'semantic-ui-css/components/site';
import 'semantic-ui-css/components/state';
import 'semantic-ui-css/components/sticky';
import 'semantic-ui-css/components/tab';
import 'semantic-ui-css/components/transition';
import 'semantic-ui-css/components/video';
import 'semantic-ui-css/components/visibility';
import 'semantic-ui-css/components/visit';
