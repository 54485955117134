import './js/shim/shim-jquery';
import './js/shim/shim-semantic-ui';

import 'semantic-ui-css/semantic.css';

import 'sylius/ui-resources/js/app';
import './js/app';

import 'sylius/ui-resources/sass/main.scss';
import './sass/main.scss';

// import './img/admin-logo.jpg';
import 'sylius/ui-resources/img/myevent-logo.svg';
import 'sylius/ui-resources/img/myevent-logo.png';
