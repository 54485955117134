const flattenObject = function (obj, parent, res = {}) {
  for(let key in obj){
    if(typeof obj[key] == 'object'){
      let propName = parent ? parent + '_' + key : key;
      flattenObject(obj[key], propName, res);
    } else {
        res[parent] = obj[key];
    }
  }

  return res;
}

const renderErrors = function(data, $form, formName) {
  let errors = flattenObject(data.errors)

  $form.removeClass('error');
  $('body').find('.sylius-validation-error').remove();

  $('.field').each(function() {
    $(this).removeClass('error');
  })
  
  Object.keys(errors).map(function(key, value) {
    let field = $('#'+formName+'_'+key).closest('.field');
    field.addClass('error');
    field.append(
      '<div class="ui red pointing label sylius-validation-error">'+
        errors[key]+
      '</div>'
    )
  });

  $form.removeClass('loading');
}

export { renderErrors };