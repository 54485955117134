import 'semantic-ui-css/components/accordion';
import 'dropzone/dist/dropzone.css';
import $ from 'jquery';
import 'jquery.dirtyforms/jquery.dirtyforms';

import 'sylius/ui/app';
import 'sylius/ui/sylius-auto-complete';
import 'sylius/ui/sylius-product-auto-complete';

import './sylius-compound-form-errors';
import './sylius-menu-search';
import './sylius-product-images-preview';

//owl
import './owl-permission';
import './owl-notification';
import './owl-project';

import './jquery.form';
import './owl-form';
import './owl-modal-ajax';
import './utils';
import GridComponent from 'sylius/ui/owl-grid';
import './owl-countdown-session';

$(window).off('beforeunload');

import * as Turbo from '@hotwired/turbo';
 
var isLoadPage = false;

Turbo.session.adapter.__proto__.visitRequestCompleted = function(visit) {
  
  function isSuccessful(statusCode) {
    return statusCode >= 200 && statusCode < 300;
  }

  if (visit.response) {
    const { statusCode, responseHTML } = visit.response
    const snapshot = Turbo.PageSnapshot.fromHTMLString(responseHTML);
    visit.render(async () => {
      if (visit.view.renderPromise) await visit.view.renderPromise
      if (isSuccessful(statusCode) && responseHTML != null) {
        await visit.view.renderPage(snapshot, false, visit.willRender)
        visit.adapter.visitRendered(visit)
        visit.complete()
      } else {
        await visit.view.renderError(snapshot)
        visit.adapter.visitRendered(visit)
        visit.fail()
      }

      visit.view.snapshotCache.put(new URL(visit.location.href), snapshot)
    })
  }
}

document.addEventListener('turbo:before-cache',  (event) => {
  event.preventDefault();
  let checkboxAll = $('[data-js-bulk-checkboxes');

  if(checkboxAll.is(':checked')) {
    checkboxAll.trigger('click');
  }

  $('.modals').modal('hide all');
  $('body').removeClass('dimmable scrolling');

});

document.addEventListener('turbo:render',  (event) => {
  $(document).trigger('session-lifetime-up');

  if ($('.sylius-grid-wrapper table').length) {
    const grid = new GridComponent(document.querySelector('.sylius-grid-wrapper'));

    if(!isLoadPage) {
      grid.removeLoading();
    }else{
      grid.addLoading();
    }
  }
}) 

document.addEventListener('turbo:before-fetch-request',  (event) => {
  isLoadPage = true;

  if ($('.sylius-grid-wrapper table').length) {
    const grid = new GridComponent(document.querySelector('.sylius-grid-wrapper'));

    grid.addLoading();
  }
})

document.addEventListener('turbo:before-fetch-response', (event) => {
  isLoadPage= false;

  $('.flashes-login').remove();

  if ($('.sylius-grid-wrapper table').length) {
    const grid = new GridComponent(document.querySelector('.sylius-grid-wrapper'));

    grid.removeLoading();
  }
})

document.addEventListener("turbo:load", () => {

  if($('body').find('.tinymce').length) {
    initTinyMCE();
  }

  // permission
  $('.ui.checkbox-permission').changePermission();

  $('.sylius-autocomplete').autoComplete();

  $('.product-select.ui.fluid.multiple.search.selection.dropdown').productAutoComplete();

  $('table thead th.sortable').on('click', (event) => {
    window.location = $(event.currentTarget).find('a').attr('href');
  });

  $('form.is-ajax').ajaxForm();
  $('.ajax-modal-button').each((index, element) => {
    if ($._data($(element).get(0), 'events') == undefined) {
      $(element).ajaxModal();
    }
  })

  $('#actions a[data-form-collection="add"]').on('click', () => {
    setTimeout(() => {
      $('select[name^="sylius_promotion[actions]"][name$="[type]"]').last().change();
    }, 50);
  });
  $('#rules a[data-form-collection="add"]').on('click', (event) => {
    const name = $(event.target).closest('form').attr('name');

    setTimeout(() => {
      $(`select[name^="${name}[rules]"][name$="[type]"]`).last().change();
    }, 50);
  });

  $(document).on('collection-form-add', () => {
    $('.sylius-autocomplete').each((index, element) => {
      if ($._data($(element).get(0), 'events') == undefined) {
        $(element).autoComplete();
      }
    });
  });
  $(document).on('collection-form-update', () => {
    $('.sylius-autocomplete').each((index, element) => {
      if ($._data($(element).get(0), 'events') == undefined) {
        $(element).autoComplete();
      }
    });
  });

  $('.sylius-tabular-form').addTabErrors();
  $('.ui.accordion').addAccordionErrors();

  $('body').on('DOMNodeInserted', '[data-form-collection="item"]', (event) => {
    if ($(event.target).find('.ui.accordion').length > 0) {
      $(event.target).find('.ui.accordion').accordion();
    }
  });

  $('#more-details').accordion({ exclusive: false });

  $('.sylius-admin-menu').searchable('.sylius-admin-menu-search-input');

  $('.accept-notification').acceptNotification();

  $('#sylius_admin_user_role').on('change', function() {
    if (this.value == 51) {
      $('.field-admin-user-companies').css('display', 'none');
    } else { 
      $('.field-admin-user-companies').css('display', 'block');
    }
  });

  $("#project-uploader").dropzone({
    url: $("#project-uploader").data("url"),
    dictDefaultMessage: "Upuść lub kliknij, aby wgrać pliki",
    init: function () {
      this.on("complete", function (file, responseText) {
        let response = JSON.parse(file.xhr.response);

        $('.list-files-cards').prepend(
          '<div class="card" style="width: calc(100% - 1em); background: #21BA45;">'+
            '<div class="content">'+
              '<a class="ui blue label" href="/files/'+response.path+'" style="margin-left: 0" target="_blank">'+response.name+'</a>'+
            '</div>'+
          '</div>'
        );
      });
    }
  });

  $('.removeMantis').removeMantis();

  $('#add-comment-project').addCommentProject();
});

window.$ = $;
window.jQuery = $;
