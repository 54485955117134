import $ from 'jquery';
import redirect from 'sylius/ui/owl-redirect';
import { renderErrors } from './utils';

$.fn.extend({ 
  removeMantis() {
    $('body').on('click', '.delete-mantis', function(e){
      let buttonaData = $(this).data();

      $("#confirmation-button").off( "click" );

      $('#confirmation-modal').modal('show');

      $('#confirmation-button').on('click', (event) => {
        $.ajax({
          type: 'DELETE',
          url: buttonaData.url,
          dataType: 'json',
          contentType: 'application/json',
          success: function(data, textStatus, xhr){
            redirect(xhr.getResponseHeader('x-owl-location'));
          },
          error: function (xhr) {
      
          }
        });
      });
    });
}});

$.fn.extend({
  addCommentProject() {

    if(this.length) {
      let dataFormComment = $(this).data();
      let commentFromLocalSotrage = localStorage.getItem('last-comment-'+dataFormComment.projectId);

      if(commentFromLocalSotrage !== null) {
        $('textarea[name="owl_project_comment[description]"]').val(commentFromLocalSotrage);
        localStorage.removeItem('last-comment-'+dataFormComment.projectId);
      }
    }

    this.on('submit', function(event) {
      event.preventDefault();
      var btn = $(":focus"),
          $this = $(this),
          formName = $this.attr('name'),
          formAction = $this.attr('action'),
          formMethod = $this.find('input[name=_method]').val() ?? $this.attr('method'),
          isAlert = $(this).find('.ui.checkbox'),
          isClickedConfirmation = false;

      $("#reject-project-add-comment").off( "click" );
      $("#confirmation-project-add-comment").off( "click" );

      const sendComment = () => {
        if(btn.hasClass('save-action')) {
          $this.addClass('loading');
      
          $.ajax({
            type: formMethod,
            url: formAction,
            data: $this.serialize()+ '&save_action=' + btn.val(),
            dataType: 'json',
            success: function(data, textStatus, xhr){
              setTimeout(function(){
                redirect(xhr.getResponseHeader('x-owl-location'));
              }, 400);
            },
            error: function (xhr) {
              if(xhr.status === 422 ) {
                renderErrors(xhr.responseJSON, $this, formName)
              } else {
                return new Error(xhr);
              }
            }
          });
        }
      }

      if(isAlert.checkbox('is checked')) {
        $('#confirmation-project-comment-modal').modal({
          onHide: () => {
            if(!isClickedConfirmation) {
              $this.removeClass('loading');
            }
          }
        }).modal('show');

        $('#reject-project-add-comment').on('click', () => {
          isAlert.checkbox('toggle');
          isClickedConfirmation = true;
          sendComment();
        })

        $('#confirmation-project-add-comment').on('click', () => {
          isClickedConfirmation = true;
          sendComment();
        })
      }else{
        sendComment();
      }
    });
  }
});

