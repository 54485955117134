var myTimer;

function clock() {
  let isRunning = false;
  let countdown = null;
  let totalSeconds = 0;
  let init = true;
  let startTime = null;
  const $buttonLogin = '<a id="login-modal-session-lifetime" class="ajax-modal-button" data-show-event="show-login-form-session-lifetime" data-url="/" data-closable="true"></a>';

  if(document.body.contains(document.getElementById('countdown'))) {
    myTimer = setInterval(sessionLifetime, 1000);
    isRunning = true;
    countdown = document.getElementById("countdown");
    totalSeconds = countdown.dataset.sessionLifetime;
    startTime = new Date((new Date()).getTime() + (1000 * totalSeconds))

    if(!document.body.contains(document.getElementById('login-modal-session-lifetime'))) {
      $('body').append($buttonLogin);
    }

    $.ajaxPrefilter(function( options, originalOptions, jqXHR ) {
      resetSessionLifetime();
    });
  }



  $(document).on('session-lifetime-up', function () {
    if(document.body.contains(document.getElementById('countdown'))) {
      if(!isRunning) {
        isRunning = true;
        startTime = new Date((new Date()).getTime() + (1000 * totalSeconds))
        myTimer = setInterval(sessionLifetime, 1000);
        if(!document.body.contains(document.getElementById('login-modal-session-lifetime'))) {
          $('body').append($buttonLogin);
        }
      }

      resetSessionLifetime();
    } else {
      isRunning = false;
      clearInterval(myTimer);
    }
  });

  function sessionLifetime() {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;

    countdown.innerHTML =
      hours + "h " + minutes + "m " + seconds + "s";

    if(init) {
      init = false;
      countdown.classList.remove("loading");
    }

    totalSeconds = Math.round((startTime.getTime() - (new Date()).getTime())/1000);

    if (totalSeconds < 0) {
      clearInterval(myTimer);

      $(document).on('show-login-form-session-lifetime', function () {
        $('#ajax-modal').prepend(`
        <h1 class="ui header">
          <i class="info icon"></i>
          <div class="content">
            Twoja sesja wygasła !
            <div class="sub header">Proszę się zalogować ponownie, aby dalej korzystać z panelu</div>
          </div>
        </h1>`
        )

        $('#ajax-modal #wrap-logo').remove();
        $('#ajax-modal .login-page-description').remove();

        let $comment = $('textarea[name="owl_project_comment[description]"]');

        if ($comment.length && $comment.val() !== '') {
          let dataFormComment = $('#add-comment-project').data();
          localStorage.setItem("last-comment-"+dataFormComment.projectId, $comment.val());
        }
      });

      $('#login-modal-session-lifetime').trigger('click');
    }
  }

  function resetSessionLifetime() {
    countdown = document.getElementById("countdown");
    totalSeconds = countdown.dataset.sessionLifetime;
    startTime = new Date((new Date()).getTime() + (1000 * totalSeconds))
    init = true;
  }
}

clock();